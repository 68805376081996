import React, { useEffect, useState } from 'react';
import butterfly from '../img/butterfly.png';
import interestsDb from '../data/interests'

function Interests(props) {
  let [interests, setInterests] = useState([])

  useEffect(() => {
    setInterests(interestsDb)
  }, [])

    return (
      <div className='component-page'>
          <div className='butterfly-component'>
            <img src={butterfly} className='butterfly-img' />
          </div>
          <div className='interests-body-left' id='interests'>
            <div className='component-title'>Specialities & Interests</div>
            <div className="interests">{interests.map(interest => (<div key={interest.id} className='interest'>{interest.item}
                  </div>))}</div>
          </div>
      </div>
    )
}

export default Interests

